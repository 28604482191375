<template>
    <div>
   <b-table hover responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      no-provider-pagin = true
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
    

      <template #cell(summary)="row">
        <button style="margin-left:5px;margin-top:5px;" class="btn btn-danger text-white font-weight-bold" @click="displayModal(row)">Remove</button>      
      </template>

    </b-table>
            <Modal v-model="showModal" title="Delete Concern">
                <p>Are you sure you would like to delete this concern?  </p>
                <button @click="showModal = false" class="btn btn-outline-primary">Back</button>
                <button @click="deleteConcern(concernToDelete)" style="float:right;" class="btn btn-danger">Delete</button>
            </Modal>
        <div class="vld-parent">
             <loading :active.sync="isLoading" 
        :can-cancel="false"        
        :is-full-page="fullPage"></loading>
        </div>
  </div>
</template>

<script>
 //import axios from 'axios'
 import {clientService} from '../../_services'
 import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
  export default {
    props:{
      items: Array
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        concernToDelete: "",
        showModal: false,
        
        fields: [
          { key: 'considerationName', label: 'Concern', sortable: true, sortDirection: 'desc' },
          { key: 'note', label: 'Notes', sortable: true, class: 'text-center' },
        
          { key: 'summary', label: '' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 20,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: []
      }
    },
    
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
    methods: {
      // emit add consideration event 
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      deleteConcern(id){
          this.isLoading = true
          var clientId = this.$route.params.id;
          clientService.deleteConcern(id, clientId)
          .then((response) => {
              this.isLoading = false;
              this.showModal = false;
              //this.items = response.data;
              this.$emit("set-new-items", response.data)
              this.$toast.success("Successfully deleted Concern!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
          }, () => {
              this.isLoading = false;
              this.$toast.error('Unable to delete concern.', {
                  position: 'bottom-center',
                  timeout: 5000,
                  hideProgressBar: true
              })
          })
      },
      displayModal(concernToDeleteId){
          this.showModal = true;
          this.concernToDelete = concernToDeleteId.item.id;
      }
     

    },
    components:{
        'Modal':VueModal,
        Loading
    }
  }
</script>

<style>
    
</style>